export class Font {
    static Regular = "Lato, sans-serif" // Regular (w400)
    static Bold = "Lato-Bold, sans-serif" // (w700)
    static Black = "Lato-Black, sans-serif" // Black equals "more bold" (w900)
}

/*
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/

/**
 * The exact mapping of pixel sizes to rem units.
 * 1rem = 16px.
 */
export enum PxRem {
    _0 = "0rem",
    _5 = "0.3125rem",
    _4 = "0.25rem",
    _10 = "0.625rem",
    _12 = "0.75rem",
    _14 = "0.875rem",
    _15 = "0.9375rem",
    _16 = "1rem", // Base value
    _20 = "1.25rem",
    _24 = "1.5rem",
    _32 = "2rem",
    _40 = "2.5rem",
    _42 = "2.625rem",
    _45 = "2.8125rem",
    _64 = "4rem",
}

/**
 * Pixel sizes are taken from Brex.
 * If you want bigger sizes, it is recommended to not change these, but to add _0, _00, _000, etc.
 */
export class FontSize {
    // Pixel sizes are taken from Brex
    static _0 = PxRem._45
    static _1 = PxRem._42 // 38
    static _2 = PxRem._24 // 24
    static _3 = PxRem._20 // 20
    static _4 = PxRem._15 // 16
    static _5 = PxRem._14 // 14
    static _6 = PxRem._12 // 12
}

export class Color {
    static White = "#FFF"
    static TransparentWhite = "rgba(244,244,244,0.55)"
    static LightGray = "#ECF0F3"
    static DarkLightGray = "#DADCDE"
    static Blue = "#0862F7"
    static TransparentBlue = "rgba(0,140,212,0.35)"
}

export const BorderRadius = PxRem._5
