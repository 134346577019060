import { GqlBookingContainerFragment } from "../../../generated/graphql/types"
import { GqlBookingFragment } from "../../../generated/graphql/types"
import { ContainerCardDetailIcon } from "../Styles/ContainerCard"
import { ContainerCardContent } from "../Styles/ContainerCard"
import { Label5Gray } from "../Styles/Labels"
import { Box } from "@mui/material"
import { Card } from "@mui/material"
import { Carousel } from "react-bootstrap"
import { GqlBookingContainerImageFragment } from "../../../generated/graphql/types"
import { StyledCardActionArea } from "../Styles/ContainerCard"
import { LabelBlack4 } from "../Styles/Labels"
import { Space } from "../Styles/Spacer"
import { FlexRow } from "../Styles/Styles"
import { ContainerDetailsImage } from "./ContainerDetailsMedia"
import useMeasure from "react-use-measure"

export function ContainerDetailsCard({ data }: ContainerDetailsCardArgs) {
    const [imageBoxRef, bounds] = useMeasure()

    return (
        <Card sx={{ boxShadow: 0, height: "100%" }}>
            <StyledCardActionArea disableRipple>
                <Box ref={imageBoxRef}>
                    <ContainerImagesCarousel size={bounds.width} images={data.images} />
                    <ContainerCardCommodityTypeOverlay />
                </Box>
                <ContainerCardContent>
                    <FlexRow>
                        <LabelBlack4>{data.shippingContainerNumber}</LabelBlack4>
                        <Space />
                        <LabelBlack4>{data.stock?.mass ?? 0}mt</LabelBlack4>
                    </FlexRow>
                    <FlexRow>
                        <Label5Gray>{data.commodityCategoryDisplayString}</Label5Gray>
                        <Interpunct />
                        <Label5Gray>{data.booking?.externalBookingNumber}</Label5Gray>
                        <Interpunct />
                        <Label5Gray>{data.booking?.steamshipLine}</Label5Gray>
                    </FlexRow>
                    <Label5Gray>{portsData(data.booking)}</Label5Gray>
                </ContainerCardContent>
            </StyledCardActionArea>
        </Card>
    )
}

function ContainerImagesCarousel({ size, images }: { size: number; images?: GqlBookingContainerImageFragment[] }) {
    if (!images) {
        return <ContainerDetailsImage size={size} />
    }

    return (
        <Carousel data-interval="false">
            {images.map((image, index) => (
                // Simplest way to disable auto-slide. 5 is a magic number - without it the interval equals 0 somehow.
                <Carousel.Item key={index} interval={Number.MAX_SAFE_INTEGER / 5}>
                    <ContainerDetailsImage size={size} image={image} />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

const Interpunct = () => <Label5Gray style={{ marginLeft: "0.25rem", marginRight: "0.25rem" }}>·</Label5Gray>

const ContainerCardCommodityTypeOverlay = () => <ContainerCardDetailIcon> 🪵 Sawlogs </ContainerCardDetailIcon>

function portsData(booking?: GqlBookingFragment): string {
    const origin = booking?.originPort
    const destination = booking?.destinationPort
    if (!origin || !destination) return ""
    return `${origin.name}, ${origin.countryCode} > ${destination.name}, ${destination.countryCode}`
}

interface ContainerDetailsCardArgs {
    data: GqlBookingContainerFragment
}
