import { GqlUserFragment } from "../../generated/graphql/types"

export enum AuthPresentationState {
    LoggedOut = "LoggedOut",
    LoggedIn = "LoggedIn",
}

export class AuthStore {
    userEmail: string | undefined = undefined // User only for the emulators login
    user: GqlUserFragment | undefined = undefined

    get presentationState() {
        return this.user != undefined ? AuthPresentationState.LoggedIn : AuthPresentationState.LoggedOut
    }

    reset() {
        this.userEmail = undefined
        this.user = undefined
    }
}
