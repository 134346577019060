import { GqlCommodityInstanceFragment } from "../../../generated/graphql/types"
import { Color } from "../Styles/Generic"

class CommodityImageDrawing {
    draw = (isEditable: boolean, log: GqlCommodityInstanceFragment, perimeter: IdentifiableGridPoint[], center?: Point, logPoints?: MidPoints) => {
        let tag = isEditable ? "B" : "A"
        const canvas: HTMLCanvasElement | null = document.querySelector("#" + tag + log.id)
        if (canvas == null) {
            return
        }
        const context = canvas.getContext("2d")
        if (context == null) {
            return
        }

        const url = log.details.imageUrl
        if (url === undefined) {
            return
        }

        var img = new Image()
        img.onload = () => {
            context.clearRect(0, 0, canvas.width, canvas.height)
            context.drawImage(img, 0, 0, canvas.width, canvas.height)
            if (isEditable) {
                this.editableDraw(context, canvas.height, perimeter, log, center, logPoints)
            } else {
                this.staticDraw(context, log, canvas.height, perimeter)
            }
        }
        img.src = url
    }

    staticDraw = (context: any, log: GqlCommodityInstanceFragment, height: number, perimeter: IdentifiableGridPoint[]) => {
        if (!perimeter.length) {
            return
        }
        this.drawAndFillPerimeter(context, height, perimeter)
        this.drawQrLines(context, height, log.details.points.qrCodePoints)
        this.drawLogLines(context, height, log.details.points.diameterOnePoints, log.details.points.diameterTwoPoints)
        this.drawPerimeterPointsWithLines(context, height, perimeter)
    }

    editableDraw = (context: any, height: number, perimeter: IdentifiableGridPoint[], log?: GqlCommodityInstanceFragment, center?: Point, logPoints?: MidPoints) => {
        this.drawAndFillPerimeter(context, height, perimeter)
        this.drawPerimeterPoints(context, perimeter)
        if (logPoints !== undefined) {
            this.drawMidPoints(Color.White, context, logPoints, height)
        }

        if (center !== undefined) {
            this.drawCenterPoint(context, center.x * height, center.y * height)
        }
    }

    drawQrLines = (context: any, height: number, points: Point[]) => {
        let orderedPoints = this.getOrderedPoints(points)
        if (orderedPoints === undefined) {
            return
        }
        this.drawPoints(Color.Blue, context, orderedPoints, height)
    }

    drawLogLines = (context: any, height: number, diameterOne: Point[], diameterTwo: Point[]) => {
        let midPoints: MidPoints = {
            first: diameterOne,
            height: 0,
            second: diameterTwo,
            width: 0,
        }
        this.drawMidPoints(Color.White, context, midPoints, height)
    }

    drawAndFillPerimeter = (context: any, height: number, points?: IdentifiableGridPoint[]) => {
        if (points === undefined) {
            return
        }
        if (!points.length) {
            return
        }
        let region = this.drawPerimeter(context, height, points)
        if (region === undefined) {
            return
        }
        context.fillStyle = Color.TransparentBlue
        context.fill(region)
    }

    drawPerimeter = (context: any, height: number, points: IdentifiableGridPoint[]): Path2D | undefined => {
        if (points === undefined) {
            return
        }
        if (!points.length) {
            return
        }
        let start = points[0]
        let region = new Path2D()
        region.moveTo(start.point.x, start.point.y)
        points.map((point) => region.lineTo(point.point.x, point.point.y))
        region.closePath()
        return region
    }

    drawPerimeterPointsWithLines = (context: any, height: number, points: IdentifiableGridPoint[]) => {
        if (points.length < 2) {
            return
        }
        if (!points.length) {
            return
        }
        let reducedPoints = points
        let originalFirst = reducedPoints[0]

        while (reducedPoints.length >= 0) {
            let first = reducedPoints[0]
            let second = reducedPoints[1]

            reducedPoints = reducedPoints.filter((point) => {
                if (point.point.x === first.point.x && point.point.y === first.point.y) {
                    return false
                }
                return true
            })
            let altSecond = second
            if (first === undefined) {
                return
            }
            if (second === undefined) {
                return
            }
            if (reducedPoints.length <= 1) {
                altSecond = originalFirst
            }
            this.drawTwoPoints(Color.Blue, context, first.point, altSecond.point)
        }
    }

    drawPerimeterPoints = (context: any, points: IdentifiableGridPoint[]) => {
        points.map((point) => this.drawEditablePoint(context, point.point.x, point.point.y))
    }

    getOrderedPoints = (original: Point[]): Point[] | undefined => {
        if (original.length !== 4) {
            return
        }
        let points = [...original]
        let sortVertically = points.sort((a, b) => a.y + b.y)
        let top = sortVertically[0]
        let altTop = sortVertically[1]
        let bottom = sortVertically[3]
        let altBottom = sortVertically[2]
        let tops = [top, altTop]
        let bottoms = [bottom, altBottom]
        let topsSortedX = tops.sort((a, b) => a.x - b.x)
        let botSortedX = bottoms.sort((a, b) => a.x - b.x)
        let ordered: Point[] = [topsSortedX[0], topsSortedX[1], botSortedX[0], botSortedX[1]]
        return ordered
    }

    drawTwoPoints = (color: string, context: any, first: Point, second: Point) => {
        const x1 = first.x
        const y1 = first.y
        const x2 = second.x
        const y2 = second.y
        this.drawLine(context, [x1, y1], [x2, y2], color)
    }

    drawPoints = (color: string, context: any, points: Point[], height: number) => {
        const x1 = points[0].x * height
        const y1 = points[0].y * height
        const x2 = points[1].x * height
        const y2 = points[1].y * height
        const x3 = points[2].x * height
        const y3 = points[2].y * height
        const x4 = points[3].x * height
        const y4 = points[3].y * height
        this.drawLine(context, [x1, y1], [x2, y2], color)
        this.drawLine(context, [x1, y1], [x3, y3], color)
        this.drawLine(context, [x3, y3], [x4, y4], color)
        this.drawLine(context, [x2, y2], [x4, y4], color)
    }

    drawMidPoints = (color: string, context: any, points: MidPoints, height: number) => {
        if (points.first.length < 2) {
            return
        }
        if (points.second.length < 2) {
            return
        }
        let topMid = points.first[0]
        let bottomMid = points.first[1]
        let leftMid = points.second[0]
        let rightMid = points.second[1]
        const x1 = topMid.x * height
        const y1 = topMid.y * height
        const x2 = bottomMid.x * height
        const y2 = bottomMid.y * height
        const x3 = leftMid.x * height
        const y3 = leftMid.y * height
        const x4 = rightMid.x * height
        const y4 = rightMid.y * height
        this.drawLine(context, [x1, y1], [x2, y2], color)
        this.drawLine(context, [x3, y3], [x4, y4], color)
    }

    drawLine = (context: any, start: [number, number], end: [number, number], color: string) => {
        context.strokeStyle = color
        context.lineWidth = 3
        context.beginPath()
        context.moveTo(...start)
        context.lineTo(...end)
        context.stroke()
        context.closePath()
    }

    drawEditablePoint = (context: any, x: number, y: number) => {
        context.strokeStyle = Color.White
        context.fillStyle = Color.TransparentWhite
        context.beginPath()
        context.roundRect(x - 10, y - 10, 25, 25, 80)
        context.fill()
        context.stroke()
    }
    drawCenterPoint = (context: any, x: number, y: number) => {
        context.strokeStyle = Color.TransparentWhite
        context.fillStyle = Color.TransparentWhite
        context.beginPath()
        context.roundRect(x - 50, y - 50, 100, 100, 80)
        context.fill()
        context.stroke()
    }
}

export const commodityImageDrawing = new CommodityImageDrawing()

export interface Point {
    x: number
    y: number
}

export interface IdentifiableGridPoint {
    id: string
    point: Point
}

interface MidPoints {
    first: Point[]
    second: Point[]
    height: number
    width: number
}
