import { useEffect } from "react"
import { GqlCommodityInstanceFragment } from "../../../generated/graphql/types"
import { StyledCommodityImageCanvas } from "../Styles/Commodity"
import { commodityImageDrawing, IdentifiableGridPoint, Point } from "./CommodityImageDrawing"

export const StaticCommodityImageView = (props: CommodityImageViewProps) => {
    useEffect(() => {
        const identifiablePerimeter = formatIdentifiablePerimeterPoints(props.perimeter, props.size)
        commodityImageDrawing.draw(false, props.commodity, identifiablePerimeter)
    }, [props.commodity, props.perimeter, props.size])

    return <CommodityImageView {...props} />
}

const CommodityImageView = ({ tag, size, commodity, onClick }: CommodityImageViewProps) => (
    <StyledCommodityImageCanvas id={tag + commodity.id} onClick={onClick} key={commodity.id} width={size} height={size} />
)

interface CommodityImageViewProps {
    tag: "A" | "B"
    size: number
    commodity: GqlCommodityInstanceFragment
    perimeter?: Point[]
    onClick: () => void
}

function formatIdentifiablePerimeterPoints(points: Point[] | undefined, size: number): IdentifiableGridPoint[] {
    if (points == undefined) {
        return []
    }
    let identifiables = points.map((point, index) => {
        let identifiable: IdentifiableGridPoint = {
            id: index.toString(),
            point: { x: point.x * size, y: point.y * size },
        }
        return identifiable
    })
    return identifiables
}
