import { useEffect } from "react"
import { GqlBookingContainerImageFragment } from "../../../generated/graphql/types"
import { computerVisionDrawing } from "./ComputerVisionDrawing"

export const ContainerDrawingView = ({ height, width, image }: { height: number; width: number; image?: GqlBookingContainerImageFragment }) => {
    const elementId = image ? image.id : "na"

    useEffect(() => {
        computerVisionDrawing.clear(elementId)
        const points = image?.boundingBoxes?.boundingBoxes
        if (points !== undefined) {
            computerVisionDrawing.drawMany(points, height, width, elementId)
            return
        }
    }, [width, image])

    return <canvas style={{ position: "absolute", top: 0 }} height={height} width={width} id={elementId} />
}
