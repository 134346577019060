import { GqlPointFragment } from "../../../generated/graphql/types"
import { Color } from "../Styles/Generic"

class ComputerVisionDrawing {
    drawMany = (many: GqlPointFragment[][], height: number, width: number, id: string) => {
        many.map((points) => this.draw(points, height, width, id))
    }

    draw = (points: GqlPointFragment[], height: number, width: number, id: string) => {
        const canvas = this.getCanvas(id)
        const context = this.getContext(canvas)
        if (!canvas || !context) {
            return
        }
        const resizedPoints = points.map((point): GqlPointFragment => ({ x: point.x * width, y: point.y * height }))
        this.staticDraw(context, resizedPoints)
    }

    clear = (id: string) => {
        const canvas: HTMLCanvasElement | null = document.querySelector("#" + id)
        const context = canvas?.getContext("2d")
        if (!canvas || !context) {
            return
        }
        context.clearRect(0, 0, canvas.width, canvas.height)
    }

    getCanvas = (id: string): HTMLCanvasElement | null => {
        return document.querySelector("#" + id)
    }

    getContext = (canvas: HTMLCanvasElement | null): CanvasRenderingContext2D | undefined | null => {
        return canvas?.getContext("2d")
    }

    staticDraw = (context: any, points: GqlPointFragment[]) => {
        if (!points.length) {
            return
        }
        this.drawAndFillPoints(context, points)
    }

    drawAndFillPoints = (context: any, points: GqlPointFragment[]) => {
        const region = this.drawPerimeter(context, points)
        if (region === undefined) {
            return
        }
        context.fillStyle = Color.TransparentBlue
        context.fill(region)
    }

    drawPerimeter = (context: any, points: GqlPointFragment[]): Path2D | undefined => {
        const region = new Path2D()
        context.strokeStyle = Color.Blue
        context.lineWidth = 2
        context.beginPath()
        context.moveTo(points[0].x, points[0].y)
        region.moveTo(points[0].x, points[0].y)

        points.map((point) => {
            context.lineTo(point.x, point.y)
            region.lineTo(point.x, point.y)
            context.stroke()
        })
        context.lineTo(points[0].x, points[0].y)
        context.stroke()
        region.closePath()
        context.closePath()
        return region
    }
}

export const computerVisionDrawing = new ComputerVisionDrawing()
