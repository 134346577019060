import React, { HTMLAttributes, SyntheticEvent } from "react"
import { Autocomplete, AutocompleteRenderInputParams, Box, Checkbox, MenuItem, TextField } from "@mui/material"
import { Color } from "../Styles/Generic"
import { FlexColumn } from "../Styles/Styles"
import { Label6, LabelBlack5 } from "../Styles/Labels"

/**
 * https://muhimasri.com/blogs/mui-multiselect/#using-autocomplete-component
 */
export function ContainerFeedAutocompleteSelector({ label, placeholder, options, onChange }: ContainerFeedSelectorProps) {
    const handleChange = (event: SyntheticEvent<Element, Event>, value: ContainerFeedSelectorOption | null) => {
        if (value !== null) {
            onChange([value.id])
        } else {
            onChange(undefined)
        }
    }

    return (
        <Autocomplete
            sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: `0px solid \`${Color.LightGray}\``,
                },
            }}
            componentsProps={{ popper: { style: { width: "fit-content" } } }}
            forcePopupIcon={false}
            disableCloseOnSelect
            options={options}
            noOptionsText="Loading options..."
            getOptionLabel={(option) => option.description}
            renderInput={(params) => <ContainerFeedTextInput label={label} placeholder={placeholder} autocompleteRenderInputParams={params} />}
            renderOption={(props, option, { selected }) => <ContainerFeedSelectorMenuItem props={props} option={option} selected={selected} />}
            onChange={handleChange}
        />
    )
}

export function ContainerFeedTextInput({ label, placeholder, onChange, autocompleteRenderInputParams }: ContainerFeedTextInputProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange !== undefined) {
            const value = event.target.value
            onChange(value.length > 0 ? value : undefined)
        }
    }

    return (
        <TextField
            {...autocompleteRenderInputParams}
            hiddenLabel
            label={label}
            margin={"dense"}
            size={"small"}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true, style: { fontSize: 17, fontFamily: "Lato-Black", color: "#000" } }}
            sx={{
                "& input::placeholder": {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
                "& fieldset": { border: "none !important" },
                border: "0px solid black",
                marginTop: "22px",
            }}
            onChange={onChange ? handleChange : undefined}
        />
    )
}

export interface ContainerFeedSelectorProps {
    label: string
    placeholder: string
    options: ContainerFeedSelectorOption[]
    onChange: (ids: string[] | undefined) => void
}

export interface ContainerFeedTextInputProps {
    label: string
    placeholder: string
    onChange?: (value: string | undefined) => void
    autocompleteRenderInputParams?: AutocompleteRenderInputParams
}

interface ContainerFeedSelectorOption {
    id: string
    description: string
    detailDescription?: string
    url?: string
}

function ContainerFeedSelectorMenuItem({ props, option, selected }: { props: HTMLAttributes<HTMLLIElement>; option: ContainerFeedSelectorOption; selected: boolean }) {
    return (
        <MenuItem {...props} key={option.id} sx={{ width: "370px", mb: 1 }}>
            <Box
                component="img"
                sx={{
                    height: 55,
                    width: 55,
                    borderRadius: 2,
                    marginRight: 1,
                }}
                src={option.url ?? "/searchPlaceholder.png"}
            />
            <FlexColumn>
                <LabelBlack5>{option.detailDescription}</LabelBlack5>
                <Label6>{option.description}</Label6>
            </FlexColumn>
            <Checkbox checked={selected} sx={{ marginLeft: "auto" }} />
        </MenuItem>
    )
}
