import { Box, ImageList } from "@mui/material"
import { useNavigate } from "react-router-dom"
import useMeasure from "react-use-measure"
import { GqlCommodityInstanceFragment } from "../../../generated/graphql/types"
import { MakeRoute } from "../../Components/Router/AppRoutes"
import store from "../../Store/Store"
import { StaticCommodityImageView } from "../Commodity/StaticCommodityImageView"
import { LabelBold4 } from "../Styles/Labels"
import { ZStack, ZStackDiv, ZStackDivBottomRight } from "../Styles/ZStack"

const COLUMNS = 4

export function SawlogsGridView({ sawlogs }: { sawlogs: GqlCommodityInstanceFragment[] }) {
    const navigate = useNavigate()

    const [imageListRef, bounds] = useMeasure()
    const imageSize = bounds.width / COLUMNS

    // TODO Fix bug when not all sawlog overlays are not drawn properly first time
    return (
        <ImageList sx={{ height: "100%", mb: 0, flexWrap: "nowrap" }} cols={COLUMNS} ref={imageListRef}>
            {sawlogs.map((commodity) => (
                <Box width={imageSize} height={imageSize}>
                    <ZStack
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            store.sawlog.sawlog = commodity
                            navigate(MakeRoute.sawlog(commodity.id))
                        }}
                    >
                        <ZStackDiv>
                            <StaticCommodityImageView tag={"A"} key={commodity.id} commodity={commodity} size={imageSize} onClick={() => {}} perimeter={commodity.details.points.perimeterPoints} />
                        </ZStackDiv>
                        <ZStackDiv style={{ paddingInlineStart: 5 }}>
                            <LabelBold4>{commodity.details.jasRoundedDiameterCm}</LabelBold4>
                        </ZStackDiv>
                        <ZStackDivBottomRight style={{ padding: 5 }}>
                            <LabelBold4>{commodity.details.sevenCharacterIdSuffix}</LabelBold4>
                        </ZStackDivBottomRight>
                    </ZStack>
                </Box>
            ))}
        </ImageList>
    )
}
