import { styled } from "styled-components"

export const ZStack = styled.div`
    width: 100%;
    display: grid;
`

export const ZStackDiv = styled.div`
    grid-row: 1;
    grid-column: 1;
`

export const ZStackDivBottomRight = styled.div`
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`
