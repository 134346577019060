import { CardMedia } from "@mui/material"
import { GqlBookingContainerImageFragment } from "../../../generated/graphql/types"
import { ContainerCardMediaTintOverlay } from "../Styles/ContainerCard"
import { ContainerDrawingView } from "./ContainerDrawingView"

export const ContainerDetailsImage = ({ size, image }: { size: number; image?: GqlBookingContainerImageFragment }) => {
    return (
        <>
            <ContainerCardMediaTintOverlay style={{ width: size, height: size }} />
            <CardMedia style={{ width: size, height: size, borderRadius: "12px" }} image={image ? image.imageUrl : "dafaultMarketingImage.png"} title="Container Photo" />
            <ContainerDrawingView width={size} height={size} image={image} />
        </>
    )
}
