import React, { useState, useEffect } from "react"
import { RoutePath } from "../../Components/Router/AppRoutes"
import { useNavigate } from "react-router-dom"
import store from "../../Store/Store"
import { useSnapshot } from "valtio"
import { components } from "../../Components/Components"
import { Box, IconButton, Toolbar, Menu, MenuItem, Button } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { AppBarNav, NavbarLogoImage } from "../Styles/Navbar"
import { OutlinedButton3 } from "../Styles/Buttons"
import { constants } from "../../Config/Constants"
import { AuthPresentationState } from "../../Store/AuthStore"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { SpacerH10 } from "../Styles/Spacer"
import { FlexRow } from "../Styles/Styles"

export const NavbarView = () => {
    const navigate = useNavigate()
    const authState = useSnapshot(store.auth)
    const timbereyeState = useSnapshot(store.timbereye)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleAccountMenuClose = () => {
        setAnchorEl(null)
    }

    const handleSignOut = () => {
        handleAccountMenuClose()
        components.auth.signOut()
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1300 && !timbereyeState.sidebarCollapsed) {
                components.timbereye.collapseSidebar()
            }
        }

        window.addEventListener("resize", handleResize)
        window.addEventListener("load", handleResize)

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("load", handleResize)
        }
    }, [])

    return (
        <AppBarNav position="fixed" color="transparent" sx={{ boxShadow: 0, backgroundColor: "#fff" }}>
            <Toolbar>
                <IconButton size="large" edge="start" color="primary" sx={{ mr: 1.5 }} onClick={() => components.timbereye.toggleSidebar()}>
                    <MenuIcon />
                </IconButton>
                <IconButton edge="start" disableRipple onClick={() => navigate(RoutePath.home)}>
                    <NavbarLogoImage src="/navbar_logo_light.png" alt="" />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                {/* <ButtonClear*/}
                {/*    onClick={() => {*/}
                {/*        navigate(RoutePath.tracker)*/}
                {/*    }}*/}
                {/* >*/}
                {/*    Track Containers*/}
                {/* </ButtonClear>*/}
                <OutlinedButton3
                    onClick={() => {
                        window.location.href = constants.iosAppAppstoreUrl
                    }}
                >
                    Get The App
                </OutlinedButton3>
                {authState.presentationState === AuthPresentationState.LoggedIn ? (
                    <>
                        <Button
                            onClick={handleAccountMenuOpen}
                            disableRipple
                            sx={{
                                ml: 2,
                                textTransform: "none",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {authState.user?.email}
                            <AccountCircleIcon sx={{ ml: 1 }} />
                            <ArrowDropDownIcon />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleAccountMenuClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                        </Menu>
                    </>
                ) : (
                    constants.allowSignIn && (
                        <FlexRow>
                            <SpacerH10 />
                            <OutlinedButton3 onClick={() => navigate(RoutePath.login)}>Sign In</OutlinedButton3>
                        </FlexRow>
                    )
                )}
            </Toolbar>
        </AppBarNav>
    )
}
