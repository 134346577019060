import { Box } from "@mui/material"
import QRCode from "react-qr-code"
import { useSnapshot } from "valtio"
import { GqlCommodityInstanceFragment } from "../../../generated/graphql/types"
import store from "../../Store/Store"
import { BasePage } from "../BasePage/BasePage"
import { Label3, Label4 } from "../Styles/Labels"
import { SpacerH32, SpacerV16 } from "../Styles/Spacer"
import { StaticCommodityImageView } from "./StaticCommodityImageView"

export const SawlogPage = () => (
    <BasePage>
        <SawlogView />
    </BasePage>
)

const SAWLOG_IMAGE_SIZE = 512
const QR_CODE_SIZE = 256

function SawlogView() {
    const sawlogStore = useSnapshot(store.sawlog)
    const sawlog = sawlogStore.sawlog as GqlCommodityInstanceFragment // Make non-readonly, just for convenience of use
    if (!sawlog) {
        return <p>Unknown sawlog</p>
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <StaticCommodityImageView tag={"A"} key={sawlog.id} commodity={sawlog} size={SAWLOG_IMAGE_SIZE} onClick={() => {}} perimeter={sawlog.details.points.perimeterPoints} />
                <SpacerV16 />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Label3>{sawlog.fingerprint}</Label3>
                </Box>
                <SawlogInfoItem label="D1 (cm)" value={sawlog.details.diameterOneCm} />
                <SawlogInfoItem label="D2 (cm)" value={sawlog.details.diameterTwoCm} />
                <SawlogInfoItem label="D (JAS)" value={sawlog.details.jasRoundedDiameterCm.toString()} />
                <SawlogInfoItem label="L (m)" value={sawlog.details.length} />
                <SawlogInfoItem label="JAS" value={sawlog.details.jas} />
                <SawlogInfoItem label="MT" value={sawlog.details.weight} />
                <SawlogInfoItem label="Species" value={sawlog.details.species} />
            </Box>
            <SpacerH32 />
            <QRCode value={sawlog.fingerprint} size={QR_CODE_SIZE} />
        </Box>
    )
}

const SawlogInfoItem = ({ label, value }: { label: string; value: string }) => (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Label4>{label}</Label4>
        <Label4>{value}</Label4>
    </Box>
)
