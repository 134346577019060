import { Box, Grid, Pagination } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import { GqlBookingContainerPreviewFragment, SortDirection, useBookingContainersQuery } from "../../../generated/graphql/types"
import ArrayUtils from "../../CommonRef/utils/ArrayUtils"
import Logger from "../../Utils/Logger"
import { InfoText } from "../Common/InfoText"
import { LoadingOverlay } from "../Common/LoadingOverlay"
import { ZStack, ZStackDiv } from "../Styles/ZStack"
import { FlexRowMatchParent } from "../Styles/Styles"
import { ContainerCard, ContainerCardArgs } from "./ContainerCard"
import { ContainerFeedFilters } from "./ContainerFeedSearchBarView"
import { MakeRoute } from "../../Components/Router/AppRoutes"

const pageSize = 50

const log = new Logger("ContainerFeedView")

export function ContainerFeedView({ filters }: { filters: ContainerFeedFilters }) {
    // Maps 1-based page number to the cursor of the last item on that page
    const mapPageToNextCursor = React.useRef(new Map<number, string>())

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: pageSize,
        page: 1,
    })

    function onPaginationModelChange(event: React.ChangeEvent<unknown>, page: number) {
        // We have the cursor, we can allow the page transition.
        // We never have a cursor for the page 0.
        if (page === 1 || mapPageToNextCursor.current.has(page - 1)) {
            setPaginationModel({
                ...paginationModel,
                page: page,
            })
        }
    }

    const [pageCount, setPageCount] = React.useState(1)

    const queryOptions = React.useMemo(
        () => ({
            variables: {
                input: {
                    filters: filters,
                    connection: {
                        pagination: { first: paginationModel.pageSize, after: mapPageToNextCursor.current.get(paginationModel.page - 1) },
                        sort: [{ fieldName: "rowCreatedAt", direction: "desc" as SortDirection }],
                    },
                },
            },
        }),
        [paginationModel, filters],
    )

    const { loading, error, data } = useBookingContainersQuery(queryOptions)

    React.useEffect(() => {
        const pageInfo = data?.bookingContainers.pageInfo
        if (pageInfo && pageInfo.endCursor) {
            // Record the endCursor

            mapPageToNextCursor.current.set(paginationModel.page, pageInfo.endCursor)

            // Calculate page count

            const hasNextPage = pageInfo.hasNextPage
            const pageMapSize = mapPageToNextCursor.current.size
            // Update page count only when we are on the last page
            if (pageMapSize === paginationModel.page) {
                setPageCount(pageMapSize + (hasNextPage ? 1 : 0))
            }
        }
    }, [data])

    const itemsRef = React.useRef<GqlBookingContainerPreviewFragment[]>([])
    const items = React.useMemo<GqlBookingContainerPreviewFragment[]>(() => {
        if (loading || data === undefined) return itemsRef.current
        itemsRef.current = filterContainersWithImages(data)
        return itemsRef.current
    }, [data, loading])

    // All layout must happen after all hooks are defined, to not lose the data stored in the hooks
    // in the case of conditional rendering, such as early return on error, etc.

    if (error) {
        log.e("ContainerFeedView", error)
        return <InfoText title="No Containers Found" text="Adjust your filters and try again" />
    }

    return (
        <Box width="100%">
            <ZStack>
                <ZStackDiv>
                    <ContainersGrid items={items} loading={loading} />
                </ZStackDiv>
                <LoadingOverlay loading={loading} />
            </ZStack>
            {!loading && items.length > 0 && (
                <FlexRowMatchParent>
                    <Pagination disabled={loading} page={paginationModel.page} count={pageCount} onChange={onPaginationModelChange} />
                </FlexRowMatchParent>
            )}
        </Box>
    )
}

function ContainersGrid({ items, loading }: { items: GqlBookingContainerPreviewFragment[]; loading: boolean }) {
    const navigate = useNavigate()

    const onBookingContainerClick = (bookingContainerId: string) => {
        // navigate(MakeRoute.bookingContainer(bookingContainerId))
    }

    if (items.length === 0 && !loading) {
        return <InfoText title="No Containers Found" text="Adjust your filters and try again" />
    } else {
        return (
            <Grid spacing={3} container>
                {items.map((data, index) => (
                    <ContainerGridItem key={index} data={data} onClick={onBookingContainerClick} />
                ))}
            </Grid>
        )
    }
}

const ContainerGridItem = ({ data, onClick }: ContainerCardArgs) => (
    <Grid item xs={3}>
        <ContainerCard data={data} onClick={onClick} />
    </Grid>
)

function filterContainersWithImages(data: any): GqlBookingContainerPreviewFragment[] {
    const containers: GqlBookingContainerPreviewFragment[] = ArrayUtils.noUndefineds(data?.bookingContainers.nodes ?? [])
    return containers.filter((container) => {
        const oneImageOfOpenDoor = container.images?.find((image) => image.type === "CONTAINER_OPEN_DOOR")
        return oneImageOfOpenDoor !== undefined
    })
}
