import useMeasure from "react-use-measure"
import { GqlBookingContainerImageFragment } from "../../../generated/graphql/types"
import { ContainerDrawingView } from "../Container/ContainerDrawingView"
import { ContainerCardMedia, ContainerCardMediaTintOverlay } from "../Styles/ContainerCard"

export const ContainerImage = ({ image, onClick }: { image?: GqlBookingContainerImageFragment; onClick?: () => void }) => {
    const [ref, bounds] = useMeasure()

    return (
        <>
            <ContainerCardMediaTintOverlay onClick={onClick} />
            <ContainerCardMedia ref={ref} image={image ? image.imageUrl : "dafaultMarketingImage.png"} title="Container Photo" />
            <ContainerDrawingView height={bounds.height} width={bounds.width} image={image} />
        </>
    )
}
