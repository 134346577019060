import { Box, Grid } from "@mui/material"
import { useParams } from "react-router-dom"
import { useBookingContainerQuery } from "../../../generated/graphql/types"
import ArrayUtils from "../../CommonRef/utils/ArrayUtils"
import Logger from "../../Utils/Logger"
import { BasePage } from "../BasePage/BasePage"
import { SawlogsGridView } from "./SawlogsGridView"
import { ContainerDetailsCard } from "./ContainerDetailsCard"
import { SpacerH32 } from "../Styles/Spacer"

const log = new Logger("ContainerPage")

export const ContainerPage = () => (
    <BasePage>
        <ContainerView />
    </BasePage>
)

function ContainerView() {
    // Validate args

    const { bookingContainerId } = useParams()
    log.d("bookingContainerId", `${bookingContainerId}`)
    if (!bookingContainerId) {
        return <p>Unknown Container</p>
    }

    //

    const queryInput = { variables: { id: bookingContainerId } }
    const { loading, error, data } = useBookingContainerQuery(queryInput)

    if (loading) return <p>Loading...</p>
    if (error) {
        log.e("ContainerFeedView", error)
        return <p>Error : {error.message}</p>
    }
    // Should not happen, because we already handling an error about
    if (!data) {
        return <p>No data</p>
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row" }} width="80vw">
            <Box sx={{ display: "flex", flexDirection: "column" }} width="40%">
                <ContainerDetailsCard data={data.bookingContainer} />
            </Box>
            <SpacerH32 />
            <Box sx={{ display: "flex", flexDirection: "column" }} width="60%">
                <SawlogsGridView sawlogs={ArrayUtils.noUndefineds(data.bookingContainer.stock?.commodityInstances ?? [])} />
            </Box>
        </Box>
    )
}
